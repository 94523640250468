import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import { requireAuth } from '../plugins/auth0'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		beforeEnter: requireAuth
	},
	{
		path: '/about',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
		beforeEnter: requireAuth
	},
	{
		path: '/profile',
		name: 'Profile',
		component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
		beforeEnter: requireAuth
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
