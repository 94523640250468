<template>
	<v-dialog
		v-model="show"
		class="create-platform-notifications-dialog">

		<template #activator="{ on }">
			<slot name="activator" :on="on">
				<v-icon v-on="on" role="default-activator">mdi-plus</v-icon>
			</slot>
		</template>

		<busie-card
			:dark="dark"
			:loading="loading"
			elevation="4"
			role="create-platform-notifications-card">

			<template #title>Create New Platform Notification</template>

			<v-form role="create-platform-notifications-form">
				<v-container class="form-container">
					<v-row>
						<v-col cols="12">
							<v-text-field
								label="Notification Message"
								v-model="message"/>
						</v-col>

						<v-col cols="12">
							<v-select
								label="Notification Type"
								v-model="type"
								:items="types"/>
						</v-col>
					</v-row>
				</v-container>
			</v-form>

			<template #actions>

				<busie-action-button
					v-if="isFormValid"
					@click="submit"
					role="confirm-action-button">
					mdi-check
				</busie-action-button>

				<busie-action-button
					@click="close"
					role="close-action-button">
					mdi-close
				</busie-action-button>

			</template>
		</busie-card>

	</v-dialog>
</template>

<script>
export default {
	name: 'create-platform-notification-modal',
	components: { },
	props: {
		dark: {
			type: [Boolean, String],
			default: false
		}
	},
	data () {
		return {
			message: '',
			type: '',
			loading: false,
			show: false,
			types: [
				{ text: 'Info', value: 'info' },
				{ text: 'Warning', value: 'warning' },
				{ text: 'Alert', value: 'alert' }
			]
		}
	},
	computed: {
		isFormValid () {
			// simple form validation ensures both properties have values
			return !!this.message && !!this.type
		}
	},
	methods: {
		submit () {
			const payload = {
				message: this.message,
				type: this.type,
				active: true
			}
			this.$emit('submit', payload)
			this.close()
		},
		close () {
			this.message = ''
			this.type = ''
			this.show = false
		}
	}
}
</script>

<style>

</style>