import axios from 'axios'

export class APIClient {
	constructor (baseUrl) {
		this.baseUrl = baseUrl || ''
	}

	async get (path, { headers }) {
		return await axios.get(this.url(path), { headers })
	}

	async post (path, { payload, headers }) {
		return await axios.post(this.url(path), payload, { headers })
	}

	async put (path, { payload, headers }) {
		return await axios.put(this.url(path), payload, { headers })
	}

	async patch (path, { payload, headers }) {
		return await axios.patch(this.url(path), payload, { headers })
	}

	async delete (path, { headers }) {
		return await axios.delete(this.url(path), { headers })
	}

	get url () {
		const base = this.baseUrl
		// if the provided path is already a full url, use it. otherwise, concatenate it to the end of the base url
		return path => String.prototype.match.call(path, /^https(s)?:\/\/.+$/) ? path : base + path
	}
}