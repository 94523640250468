<template>
	<busie-card
		role="platform-notifications-card"
		:loading="loading"
		:dark="dark"
		elevation="4">

		<template #title>Platform Notifications</template>

		<platform-notification-list
			role="platform-notifications-list"
			:items="items"
			:allow-delete="allowDelete"
			:dark="dark"
			@delete="$emit('delete', $event)"/>

		<template #actions>
			<create-platform-notifications-modal
				role="create-platform-notifications-modal"
				:dark="dark"
				v-if="allowCreate"
				@submit="$emit('submit', $event)">
				<template #activator="{ on }">
					<busie-action-button
						v-on="on"
						x-small
						elevation="24"
						role="create-action-button">
						mdi-plus
					</busie-action-button>
				</template>
			</create-platform-notifications-modal>
		</template>

	</busie-card>
</template>

<script>
import PlatformNotificationList from '../PlatformNotificationsList'
import CreatePlatformNotificationsModal from '../PlatformNotificationsModal'

export default {
	name: 'platform-notifications-card',
	components: { PlatformNotificationList, CreatePlatformNotificationsModal },
	props: {
		items: {
			type: Array,
			default: () => ([]),
			validator: v => v.reduce((acc, { type, message }) => acc && !!type && !!message, true)
		},
		allowDelete: {
			type: Boolean,
			default: false
		},
		allowCreate: {
			type: Boolean,
			default: false
		},
		dark: {
			type: [Boolean, String],
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style>

</style>