import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import { Auth0Plugin } from './plugins/auth0'
import BusieCoreComponents from '@busieinc/core'
import '@busieinc/core/dist/busie-core-components.css'

/** Import Auth0 Configuration */
import { domain, clientId } from '../auth_config.json'

// Setup the Auth0 Plugin
Vue.use(Auth0Plugin, {
	domain,
	clientId,
	onRedirectCallback: appState => {
		router.push(
			appState && appState.targetUrl
				? appState.targetUrl
				: window.location.pathname
		)
	}
})

Vue.use(BusieCoreComponents)


Vue.config.productionTip = false
new Vue({
	vuetify,
	router,
	store,
	render: h => h(App)
}).$mount('#app')

// if (typeof window !== 'undefined' && window.Vue) {
//   window.Vue.use(ComponentLibrary);
// } else {
//   console.error('Component Library is only available in a javascript enabled browser where Vue is defined');
// }