import Vue from 'vue'
import { APIClient } from '../../../api'
import { domain, clientId, dashboard_audience } from '../../../../auth_config.json'

const client = new APIClient(process.env.VUE_APP_API_GATEWAY)

const audience = dashboard_audience

export default {
	async fetchPlatformNotifications ({ commit, dispatch }) {
		const accessToken = await dispatch('fetchAccessToken')
		const headers = { Authorization: `Bearer ${accessToken}` }
		const { data } = await client.get('/platform-notifications', { headers })
		commit('SET_PLATFORM_NOTIFICATIONS', data)
	},
	async createPlatformNotification ({ commit, dispatch }, payload) {
		const accessToken = await dispatch('fetchAccessToken')
		const headers = { Authorization: `Bearer ${accessToken}` }
		const { data } = await client.post('/platform-notifications/', { headers, payload })
		console.log('successfully created a platform notification')
		commit('ADD_PLATFORM_NOTIFICATION', data)
	},
	async deletePlatformNotification ({ commit, dispatch }, id) {
		const accessToken = await dispatch('fetchAccessToken')
		const headers = { Authorization: `Bearer ${accessToken}` }
		await client.delete(`/platform-notifications/${id}/`, { headers })
		// re-fetch rather than searching and removing in place.
		commit('SET_PLATFORM_NOTIFICATIONS', [])
		await dispatch('fetchPlatformNotifications')
	},
	async fetchAccessToken ({ getters, commit }) {
		let token
		if (!getters.accesToken) {
			const options = {
				domain,
				clientId,
				audience,
				redirectUri: window.location.origin
			}
			try {
				token = await Vue.prototype.$auth.getTokenSilently(options)
			} catch (e) {
				console.error(e)
				if (e.message === 'Login required') {
					console.error('LOGIN REQUIRED!')
					token = await Vue.prototype.$auth.loginWithPopup(options)
				}
				if (e.message === 'Consent required') {
					console.error('Consent required')
					token = await Vue.prototype.$auth.loginWithRedirect(options)
				}
			}
			if (token) {
				commit('SET_ACCESS_TOKEN', token)
				return getters.accessToken
			} else {
				console.error('a token could not be found. redirecting to login in 5 seconds ...')
				setTimeout(() => {
					Vue.prototype.$auth.logout({ returnTo: window.location.origin + '/login' })
					throw new Error('Login Required')
				}, 5000)
			}
		}
	}
}