import Vue from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js'

/** Define a default action to perform after authentication */
const DEFAULT_REDIREC_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)

let instance

/** Returns the current instance of the SDK */
export const getInstance = () => instance

export const useAuth0 = ({
	onRedirectCallback = DEFAULT_REDIREC_CALLBACK,
	redirectUri = window.location.origin,
	...options
}) => {
	if (instance) return instance

	// the `instance` is simply a vue object
	instance = new Vue({
		data () {
			return {
				loading: true,
				isAuthenticated: false,
				user: {},
				auth0Client: null,
				popupOpen: false,
				error: null
			}
		},
		methods: {
			/** Authenticates the user using a popup window */
			async loginWithPopup(options, config) {
				this.popupOpen = true
				try {
					await this.auth0Client.loginWithPopup(options, config)
					this.user = await this.auth0Client.getUser()
					this.isAuthenticated = await this.auth0Client.isAuthenticated()
					this.error = null
				} catch (e) {
					this.error = e
					// eslint-disable-next-line
                    console.error(e)
				} finally {
					this.popupOpen = false
				}
			},

			/** Handles the callback when logging in using a redirect */
			async handleRedirectCallback () {
				this.loading = true
				try {
					await this.auth0Client.handleRedirectCallback()
					this.user = await this.auth0Client.getUser()
					this.isAuthenticated = true
					this.error = null
				} catch (e) {
					this.error = e
					// eslint-disable-next-line
                    console.error(e)
				} finally {
					this.loading = false
				}
			},

			/** Authenticates the user using the redirect method */
			loginWithRedirect (o) {
				return this.auth0Client.loginWithRedirect(o)
			},

			/** returns all the claims present in the ID Token */
			getIdTokenClaims (o) {
				return this.auth0Client.getIdTokenClaims(o)
			},

			/** Returns the access token. If the token is invalid or missing, a new one is retrieved. */
			getTokenSilently (o) {
				return this.auth0Client.getTokenSilently(o)
			},

			/** Gets an access token using a popup window. */
			getTokenWithPopup (o) {
				return this.auth0Client.getTokenWithPopup(o)
			},

			/** Logs the user out and removes their session on the authorization server */
			logout (o) {
				return this.auth0Client.logout({
					returnTo: window.location.origin + '/login',
					...o
				})
			}
		},

		/** `Created` Lifecycle hook instantiates the SDK Client */
		async created () {
			// Create a new instance of the SDK client using members of the given options object
			this.auth0Client = await createAuth0Client({
				...options,
				client_id: options.clientId,
				redirect_uri: redirectUri
			})

			try {
				// if the user is returning to the app after authentication ..
				const query = window.location.search
				if (query.includes('code=') && query.includes('state=')) {
					// handle the redirect and retrieve tokens
					const { appState } = await this.auth0Client.handleRedirectCallback()
					this.error = null
                    
					// notify subscribers that the redirect callback has happened, passing the appState
					// (useful for retrieving any pre-authentication state)
					onRedirectCallback(appState)
				}
			} catch (e) {
				this.error = e
				// eslint-disable-next-line
                console.error(e)
			} finally {
				// initialize internal authentication state
				this.isAuthenticated = await this.auth0Client.isAuthenticated()
				this.user = await this.auth0Client.getUser()
				this.loading = false
			}
		}
	})

	return instance
}

export const Auth0Plugin = {
	install (Vue, options) {
		Vue.prototype.$auth = useAuth0(options)
	}
}

export const requireAuth = (to, from, next) => {
	const authService = getInstance()

	const fn = () => {
		// if the user is authenticated, continue with the route
		if (authService.isAuthenticated) {
			return next()
		}

		// otherwise perform a login with redirect
		authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
	}

	// if loading has already finished, check auth state using `fn()`
	if (!authService.loading) {
		return fn()
	}

	// watch for the loading property to change before we check authentication status
	authService.$watch('loading', loading => {
		if (loading === false) {
			return fn()
		}
	})
}
