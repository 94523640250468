<template>
    <busie-list-item
		:dark="dark"
		:icon-attrs="{'color': itemIconColor}">

		<template #icon>
			{{ itemIcon }}
		</template>

        {{ item.message }}

		<template #action>
			<busie-action-button
				@click="$emit('delete', item._id)"
				v-if="allowDelete"
				role="delete-action-button"
				x-small>
				mdi-close
			</busie-action-button>
		</template>

    </busie-list-item>
</template>

<script>

export default {
	name: 'platform-notifications-list-item',
	components: { },
	props: {
		dark: {
			default: false,
			type: [String, Boolean]
		},
		item: {
			type: Object,
			default: () => ({}),
			validator: ({ _id, type, message }) => !!type && !!message && !!_id
		},
		allowDelete: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		itemIcon () {
			return {
				info: 'mdi-information',
				warning: 'mdi-alert-circle',
				alert: 'mdi-alert'
			}[this.item.type]
		},
		itemIconColor () {
			return {
				info: 'info',
				warning: 'warning',
				alert: 'error'
			}[this.item.type]
		}
	}
}
</script>

<style>

</style>