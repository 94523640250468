<template>
    <busie-list
        :dark="dark">
        <platform-notifications-list-item
            role="platform-notification"
            :item="item"
			:allow-delete="allowDelete"
            :dark="dark"
            v-for="(item, index) in items"
            :key="index"
			@delete="$emit('delete', $event)"/>
    </busie-list>
</template>

<script>
import PlatformNotificationsListItem from '../PlatformNotificationsListItem'

export default {
	name: 'platform-notifications-list',
	components: { PlatformNotificationsListItem },
	props: {
		allowDelete: {
			type: Boolean,
			default: false
		},
		items: {
			type: Array,
			default: () => ([]),
			validator: v => v.reduce(( acc, { type, message }) => acc && !!type && !!message, true)
		},
		dark: {
			type: [Boolean, String],
			default: false
		}
	}
}
</script>

<style>

</style>