<template>
  <div class="home">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <v-container>
      <v-row>
        <v-col cols="6">
          <platform-notifications-card
			@submit="handleSubmit"
			@delete="handleDelete"
            :items="platformNotifications"
            allow-create
            allow-delete/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { PlatformNotificationsCard } from '@/components/PlatformNotifications'
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'Home',
	components: {
		PlatformNotificationsCard
	},
	data () {
		return { }
	},
	async mounted () {
		await this.fetchPlatformNotifications()
	},
	computed: {
		items () {
			console.log(this.platformNotifications)
			return this.platformNotifcations.map(({ message, type }) => ({ message, type }))
		},
		...mapGetters('dashboard', ['platformNotifications'])
	},
	methods: {
		async handleSubmit (payload) {
			await this.createPlatformNotification(payload)
		},
		async handleDelete (id) {
			await this.deletePlatformNotification(id)
		},
		...mapActions('dashboard', ['fetchPlatformNotifications', 'createPlatformNotification', 'deletePlatformNotification'])
	}
}
</script>
